<template>
  <div>
    <div>
      <v-img :src="require('../assets/turathHeaderImage.png')" cover width="100vw" />
    </div>
    <v-container class="container" fluid>
      <v-overlay :value="overlay" style="text-align: center !important">
        <v-progress-circular indeterminate size="64"> </v-progress-circular>
        <h3 style="text-align: center !important; padding: 20px">جاري التحميل ...</h3>
      </v-overlay>
      <v-alert border="left" dark dense text type="warning"> <h5>الرجاء ادخال الرقم الجامعي</h5> </v-alert>
      <v-row>
        <v-col cols="8" sm="10" md="6" xl="6">
          <v-text-field dense outlined v-model="collegeNumber" label="الرقم الجامعي"></v-text-field>
        </v-col>
        <v-col cols="4" sm="2" md="6" xl="6" style="text-align: right">
          <v-btn @click="getStudentByCollegeNumber" color="primary">
            <v-icon left> mdi-magnify </v-icon>
            بحث
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="container sticky-header" fluid v-if="student != null">
      <v-card elevation="6">
        <v-row style="margin: 10px" v-if="showDisplay == 2">
          <v-col cols="3" style="text-align: center">
            <div style="text-align: right">
              <v-alert border="left" dark dense text type="warning"> <h3>يفضل ان تكون بخلفية بيضاء وصورة رسمية :</h3></v-alert>
              <h5>بدون فلاتر</h5>
              <h5>لا تظهر كامل الجسم</h5>
              <h5>ولاتكون صورة جانبية</h5>
              <h5>بدون نظارات</h5>
              <h5>واذا خالفت الشروط اعلاه تهمل الهوية</h5>
              <h5>صيغة الصورة jpg</h5>
            </div>
            <v-avatar size="300" style="margin: 10px auto">
              <img :src="photoPath" alt="الصورة الشخصية" v-if="!student?.photoPath" />
              <img :src="student.photoPath" alt="الصورة الشخصية" v-if="student?.photoPath" />
            </v-avatar>
            <div v-if="showUpload">
              <v-btn color="primary" rounded depressed :loading="isSelecting" @click="onButtonClick">
                <v-icon left> mdi-cloud-arrow-up </v-icon>
                اضافة صورة شخصية
              </v-btn>
              <input ref="uploader" class="d-none" type="file" accept=".jpg" @change="onFileChanged" />
            </div>
            <div v-else>
              <v-btn color="primary" rounded depressed :loading="isSelecting" @click="onButtonClick">
                <v-icon left> mdi-cloud-arrow-up </v-icon>
                تعديل صورة شخصية
              </v-btn>
              <input ref="uploader" class="d-none" type="file" accept=".jpg" @change="onFileChanged" />
            </div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="9">
            <div style="height: 20px"></div>
            <v-alert border="left" dark color="primary"> <h3>المعلومات الاساسية</h3> </v-alert>

            <v-alert border="left" dense text dark :color="setColor(student.statusId)" v-if="hasOwnProperty.call(student, 'studentEnglishName')">
              <h3>{{ student.statusId == 1 ? "الهوية قيد الطباعة" : student.statusId == 2 ? "تمت طباعة الهوية" : "تم رفض الطباعة" }}</h3>
            </v-alert>

            <div style="height: 20px"></div>

            <v-row>
              <v-col style="padding: 10px">
                <v-text-field outlined v-model="student.studentName" label="الاسم"></v-text-field>
                <v-text-field outlined v-model="studentEnglishName" label="الاسم انكليزي لجواز السفر" v-if="!hasOwnProperty.call(student, 'studentEnglishName')"></v-text-field>
                <v-text-field outlined v-model="student.studentEnglishName" label="الاسم انكليزي لجواز السفر" v-if="hasOwnProperty.call(student, 'studentEnglishName')"></v-text-field>
                <!-- <v-text-field outlined :disabled="!update" v-model="student.sectionName" label="القسم"></v-text-field>
                <v-text-field outlined :disabled="!update" v-model="student.sectionEnglishName" label="القسم بالانكليزي"></v-text-field> -->
                <v-text-field label="تاريخ الميلاد" v-model="student.dob" outlined></v-text-field>
                <v-text-field outlined :disabled="!update" v-model="student.mail" label="البريد" v-if="!student?.studentEmail"></v-text-field>
                <v-text-field outlined :disabled="!update" v-model="student.studentEmail" label="البريد" v-if="student?.studentEmail"></v-text-field>
                <v-text-field outlined :disabled="!update" v-model="student.nationality" label="الجنسية"></v-text-field>
                <!-- <v-text-field outlined :disabled="!update" v-model="student.gender" label="الجنس"></v-text-field>
                <v-text-field outlined :disabled="!update" :value="student.studyType * 1 == 1 ? 'صباحي' : 'مسائي'" label="نوع الدراسة"></v-text-field> -->

                <v-autocomplete v-model="student.gender" :items="gender" item-text="name" outlined label="جنس الطالب"></v-autocomplete>
                <v-autocomplete v-model="student.sectionName" :items="allSections" item-text="sectionName" value="sectionName" outlined label="القسم"></v-autocomplete>
                <v-select v-model="student.studyType" :items="studyTypes" item-text="name" outlined label="نوع الدراسة" return-object persistent-hint single-line></v-select>

                <!-- <v-text-field outlined :disabled="!update" :value="student.level | setLevel" label="المرحلة" v-if="!student?.studentLevel"></v-text-field>
                <v-text-field outlined :disabled="!update" :value="student.studentLevel | setLevel" label="المرحلة" v-if="student?.studentLevel"></v-text-field>
                <v-text-field outlined :disabled="!update" v-model="student.class" label="الشعبة" v-if="!student?.studentClass"></v-text-field>
                <v-text-field outlined :disabled="!update" v-model="student.studentClass" label="الشعبة" v-if="student?.studentClass"></v-text-field> -->
                <v-textarea v-model="notes" outlined name="input-7-4" label="الملاحظات"></v-textarea>
              </v-col>
            </v-row>
            <v-btn @click="saveStudentInformation" color="primary" style="margin: 10px" v-if="showSend">
              <v-icon left> mdi-send </v-icon>
              ارسال
            </v-btn>

            <v-btn @click="editStudentInformation(student)" color="primary" style="margin: 10px" v-else>
              <v-icon left> mdi-pencil </v-icon>
              تعديل
            </v-btn>
          </v-col>
        </v-row>

        <div v-if="showDisplay == 1" style="padding: 10px">
          <v-alert border="left" dense text dark :color="setColor(student.statusId)" v-if="hasOwnProperty.call(student, 'studentEnglishName')">
            <h3>{{ student.statusId == 1 ? "الهوية قيد الطباعة" : student.statusId == 2 ? "تمت طباعة الهوية" : "تم رفض الطباعة" }}</h3>
          </v-alert>

          <v-alert border="left" dark dense text type="warning"> <h3>يفضل ان تكون بخلفية بيضاء وصورة رسمية :</h3></v-alert>
          <h5>بدون فلاتر</h5>
          <h5>لا تظهر كامل الجسم</h5>
          <h5>ولاتكون صورة جانبية</h5>
          <h5>بدون نظارات</h5>
          <h5>واذا خالفت الشروط اعلاه تهمل الهوية</h5>
          <h5>صيغة الصورة jpg</h5>
          <v-avatar size="300" style="margin: 10px auto">
            <img :src="photoPath" alt="الصورة الشخصية" v-if="!student?.photoPath" />
            <img :src="student.photoPath" alt="الصورة الشخصية" v-if="student?.photoPath" />
          </v-avatar>
          <div style="text-align: center; margin-bottom: 10px">
            <!-- <v-btn color="primary" v-if="showUpload" rounded depressed :loading="isSelecting" @click="onButtonClickMobile">
              <v-icon left> mdi-cloud-arrow-up </v-icon>
              اضافة صورة شخصية
            </v-btn>
            <input ref="uploader" class="d-none" type="file" accept="image/*" @change="onFileChanged" /> -->

            <div v-if="showUpload">
              <v-btn color="primary" rounded depressed :loading="isSelecting" @click="onButtonClickMobile">
                <v-icon left> mdi-cloud-arrow-up </v-icon>
                اضافة صورة شخصية
              </v-btn>
              <input ref="uploader" class="d-none" type="file" accept=".jpg" @change="onFileChanged" />
            </div>
            <div v-else>
              <v-btn color="primary" rounded depressed :loading="isSelecting" @click="onButtonClickMobile">
                <v-icon left> mdi-cloud-arrow-up </v-icon>
                تعديل صورة شخصية
              </v-btn>
              <input ref="uploader" class="d-none" type="file" accept=".jpg" @change="onFileChanged" />
            </div>
          </div>

          <v-text-field outlined v-model="student.studentName" label="الاسم"></v-text-field>
          <v-text-field outlined v-model="studentEnglishName" label="الاسم انكليزي لجواز السفر" v-if="!hasOwnProperty.call(student, 'studentEnglishName')"></v-text-field>
          <v-text-field outlined v-model="student.studentEnglishName" label="الاسم انكليزي لجواز السفر" v-if="hasOwnProperty.call(student, 'studentEnglishName')"></v-text-field>
          <!-- <v-text-field outlined :disabled="!update" v-model="student.sectionName" label="القسم"></v-text-field>
          <v-text-field outlined :disabled="!update" v-model="student.sectionEnglishName" label="القسم بالانكليزي"></v-text-field> -->
          <v-text-field label="تاريخ الميلاد" v-model="student.dob" outlined></v-text-field>
          <v-text-field outlined :disabled="!update" v-model="student.mail" label="البريد" v-if="!student?.studentEmail"></v-text-field>
          <v-text-field outlined :disabled="!update" v-model="student.studentEmail" label="البريد" v-if="student?.studentEmail"></v-text-field>
          <v-text-field outlined :disabled="!update" v-model="student.nationality" label="الجنسية"></v-text-field>
          <!-- <v-text-field outlined :disabled="!update" v-model="student.gender" label="الجنس"></v-text-field>
          <v-text-field outlined :disabled="!update" :value="student.studyType * 1 == 1 ? 'صباحي' : 'مسائي'" label="نوع الدراسة"></v-text-field> -->
          <v-autocomplete v-model="student.gender" :items="gender" item-text="name" outlined label="جنس الطالب"></v-autocomplete>
          <v-autocomplete v-model="student.sectionName" :items="allSections" item-text="sectionName" value="sectionName" outlined label="القسم"></v-autocomplete>
          <v-select v-model="student.studyType" :items="studyTypes" item-text="name" outlined label="نوع الدراسة" return-object persistent-hint single-line></v-select>
          <!-- <v-text-field outlined :disabled="!update" :value="student.level | setLevel" label="المرحلة" v-if="!student?.studentLevel"></v-text-field>
          <v-text-field outlined :disabled="!update" :value="student.studentLevel | setLevel" label="المرحلة" v-if="student?.studentLevel"></v-text-field>
          <v-text-field outlined :disabled="!update" v-model="student.class" label="الشعبة" v-if="!student?.studentClass"></v-text-field>
          <v-text-field outlined :disabled="!update" v-model="student.studentClass" label="الشعبة" v-if="student?.studentClass"></v-text-field> -->
          <v-textarea v-model="notes" outlined name="input-7-4" label="الملاحظات"></v-textarea>
          <v-btn @click="saveStudentInformation" color="primary" style="margin: 10px" v-if="showSend">
            <v-icon left> mdi-send </v-icon>
            ارسال
          </v-btn>

          <!-- //T2306692 -->

          <v-btn @click="editStudentInformation(student)" color="primary" style="margin: 10px" v-else>
            <v-icon left> mdi-pencil </v-icon>
            تعديل
          </v-btn>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    // collegeNumber: "1912100225",
    collegeNumber: "",
    studentEnglishName: "",
    notes: "لايوجد",
    photoPath: "",
    update: false,
    canEdit: true,
    allSections: [],
    gender: [
      { name: "ذكر", id: 1 },
      { name: "انثى", id: 2 },
    ],

    studyTypes: [
      { name: "صباحي", studyType: 1 },
      { name: "مسائي", studyType: 2 },
    ],
    student: null,
    selectedFile: null,
    isSelecting: false,
    showUpload: true,
    overlay: false,
    showSend: true,
  }),
  computed: {
    showDisplay() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 2;
        case "xl":
          return 2;
      }
    },
  },
  created() {
    this.photoPath = require("./../assets/logo3.png");
    this.initialData();
  },

  methods: {
    setColor(statusId) {
      if (statusId == 1) {
        return "warning";
      } else if (statusId == 2) {
        return "success";
      } else {
        return "error";
      }
    },
    initialData() {
      let self = this;
      self.$http
        .get(`${self.$store.state.apiUrl}/sections`, {
          headers: {
            Authorization:
              "Barear eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZEVtcGxveWVlIjozNjEsImVtcGxveWVlTmFtZSI6Itiz2LHZiSDYsdit2YrZhSDYudmK2K_Yp9mGIiwic2VjdGlvbklkIjozLCJnZW5kZXIiOiLYp9mG2KvZiSIsIm5hdGlvbmFsaXR5IjoiSVJBUUkiLCJwYXNzd29yZCI6IiQyYiQxMCRyejZPbTJsM1RmWkc3SnZIUGFjS0guV3BnMUxPMW5HNFEzMWRwS3FkMTcvUTRpRWUxTjBSRyIsImNvbnRyYWN0RGF0ZSI6IjIwMjAtMDYtMDFUMjE6MDA6MDAuMDAwWiIsImNvbnRyYWN0VHlwZUlkIjoxLCJwaG9uZSI6IjA3ODAxMjEwNzAwIiwibWFpbCI6InN1cmEucmFoZWVtQHR1cmF0aC5lZHUuaXEiLCJkb2IiOiIxOTg1LTA5LTMwVDIxOjAwOjAwLjAwMFoiLCJzdGF0dXNJZCI6MiwibW90aGVyTmFtZSI6ItmE2KfZitmI2KzYryIsInJvbGVJZCI6NzcsImlhdCI6MTcxMzk0OTMyNSwiZXhwIjoxNzE2NTQxMzI1fQ.sX1nRTWJqQMdZtB1gnmPu98ROlqJ1CI96vyMElmDh8k",
          },
        })
        .then((res) => {
          console.log(res.data);
          self.allSections = res.data;
        })
        .catch((e) => {
          console.log(e);
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    getStudentByCollegeNumber() {
      let self = this;

      // 1912100225
      if (this.collegeNumber) {
        self.overlay = true;
        self.$http
          .get(`${self.$store.state.apiUrl}/studentIdentityFormCollegeNumber/${self.collegeNumber}`)
          .then((res) => {
            console.log(hasOwnProperty.call(res.data[0], "studentEmail"));
            if (hasOwnProperty.call(res.data[0], "studentEmail")) {
              self.canEdit = res.data[0].statusId == 2 ? false : true;
            }

            console.log(self.canEdit);
            self.overlay = false;
            if (res.data.length > 0) {
              self.student = res.data[0];
              if (self.student?.photoPath) {
                self.showUpload = !hasOwnProperty.call(self.student, "photoPath");
                self.showSend = !hasOwnProperty.call(self.student, "photoPath");
                self.student.studyType = { name: self.student.studyType == 1 ? "صباحي" : "مسائي", studyType: self.student.studyType };
              }
            } else {
              this.$toasted.error("لايوجد طالب بهذا الرقم الجامعي الرجاء التأكد من المعلومات");
            }
          })
          .catch((e) => {
            self.overlay = false;
            console.log(e);
            this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
          });
      } else {
        this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
      }
    },
    editStudentInformation(student) {
      let self = this;

      if (self.canEdit) {
        let studentData = {
          studentName: student.studentName,
          collegeNumber: student.collegeNumber,
          studentEnglishName: student.studentEnglishName,
          sectionName: student.sectionName,
          sectionEnglishName: student.sectionEnglishName,
          dob: student.dob,
          studentEmail: student.studentEmail,
          nationality: student.nationality,
          gender: student.gender,
          studyType: student.studyType.studyType,
          studentLevel: student.studentLevel,
          studentClass: student.studentClass,
          statusId: 1,
          photoPath: self.photoPath != "" ? self.photoPath : self.student.photoPath,
        };

        self.$http
          .put(`${self.$store.state.apiUrl}/studentIdentityForm/${student.idStudentIdentityForm}`, studentData)
          .then((res) => {
            this.$toasted.success("تم رفع البيانات");
            console.log(res.data);
            self.student = null;
            self.collegeNumber = "";
            self.overlay = false;
          })
          .catch((error) => {
            self.overlay = false;
            this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
          });
      } else {
        this.$toasted.error("لا يمكن تعديل البيانات لان الهوية مطبوعة");
      }
    },
    saveStudentInformation() {
      let self = this;
      self.overlay = true;

      if (self.studentEnglishName && self.photoPath && self.notes) {
        let student = {
          studentName: self.student.studentName,
          collegeNumber: self.student.collegeNumber,
          studentEnglishName: self.studentEnglishName,
          sectionName: self.student.sectionName,
          sectionEnglishName: self.student.sectionEnglishName,
          dob: self.student.dob,
          studentEmail: self.student.mail,
          nationality: self.student.nationality,
          gender: self.student.gender,
          studyType: self.student.studyType.studyType,
          studentLevel: self.student.level,
          studentClass: self.student.class,
          statusId: 1,
          photoPath: self.photoPath,
        };

        self.$http
          .post(`${self.$store.state.apiUrl}/addStudentIdentityForm`, student)
          .then((res) => {
            this.$toasted.success("تم رفع البيانات");

            self.student = null;
            self.collegeNumber = "";
            self.overlay = false;
          })
          .catch((error) => {
            self.overlay = false;
            this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
          });

        console.log(student);
      } else {
        this.$toasted.error("الرجاء رفع الصورة مع المعلومات المطلوبة");
      }
    },
    onButtonClick() {
      this.isSelecting = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      console.log(this.$refs);

      this.$refs.uploader.click();
    },

    onButtonClickMobile() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
          console.log("here");
        },
        { once: true }
      );
      console.log(this.$refs);

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      let self = this;
      this.selectedFile = e.target.files[0];

      this.isSelecting = false;
      //2322405156 this is rawan collegeNumber and the photo is in download folder
      if (false) {
      } else {
        self.overlay = true;
        let formData = new FormData();
        formData.append("file", self.selectedFile);
        formData.append("collegeNumber", self.student.collegeNumber);
        self.$http
          .post(`${self.$store.state.apiUrl}/uploadStudentImageFromStudent`, formData)
          .then((res) => {
            self.overlay = false;

            // if (hasOwnProperty.call(self.student, "photoPath")) {
            //   self.student.photoPath = `${self.$store.state.apiUrl}/${res.data.imagePath}`;
            // } else {
            self.student.photoPath = `${self.$store.state.apiUrl}/${res.data.imagePath}`;
            self.photoPath = `${self.$store.state.apiUrl}/${res.data.imagePath}`;
            // }
            console.log(self.photoPath);
            this.$toasted.success("تم رفع الصورة");
            self.showUpload = false;
          })
          .catch((error) => {
            self.overlay = false;
            this.$toasted.error("حدث خطأ في رفع الصورة");
          });
      }
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-div {
  width: 300px;
  height: 300px;
  background: rgb(121, 121, 121);
  margin: 10px auto;
  border-radius: 150px;
}
</style>
